// network-error.component.ts
import { Component } from '@angular/core';

@Component({
  standalone: true,
  template: `
    <div>
      <h1>Network Error</h1>
      <p>
        Sorry, we are having trouble connecting to the server. Please check your internet connection or try again later.
      </p>
      <!-- Add a retry button or other relevant actions -->
    </div>
  `
})
export class NetworkErrorComponent {
  // Logic for retrying or handling the network error
}
